import React from "react"
import CourseDetail from "../../components/CourseDetail"
import { getCourseDetailFor } from "../../shared/constants"
import Layout from "../../components/layout"
import SEO from "../../components/SEO"
import { seoData } from "../../shared/seoData"

export default () => {
  const course = getCourseDetailFor("first-steps-with-scala")
  const handleBuyCourseClick = () => window.open(course.buyUrl, "_self")
  let seo = seoData.firstStepsWithScala

  return (
    <>
      <SEO
        title={seo.title}
        description={seo.metaDescription}
        pathname={seo.pathname}
      />
      <Layout selectedTabValue={false} hideNews>
        <CourseDetail
          onBuyCourseClick={handleBuyCourseClick}
          course={course}
          render={() => <div />}
        />
      </Layout>
    </>
  )
}
